.gather-cover-page {
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100%;
   background-color: black;

   .tmp-title {
      color: #fff;
      font-size: 7vh;
      line-height: 110%;
      text-transform: uppercase;
      font-family: 'work-sans';
      font-weight: 550;
      letter-spacing: -1px;
      margin-bottom: 20px;
   }
}
