.gather-toc {
   position: absolute;
   top: 0px;
   left: 0px;
   bottom: 0px;
   z-index: var(--zIndexTOC);
   will-change: transform;
   overflow: hidden;
   display: flex;
   justify-content: center;
   align-items: center;
   padding-bottom: 60px;

   .gather-toc-nav {

   }

   .gather-toc-app-container {
      display: flex;
      flex-flow: row wrap;
      transition: 300ms;
      max-width: 800px;
   }

   &.overlay {
      width: 100vw;
      transition: 200ms;
      transform: translate3d(0,0,0);
      background-color: rgba(20,20,20,0.97);
      --buttonTextColor: #88827e;
      --rootMenuBackgroundColor: rgba(5,0,0,0.2);
      --buttonActiveBackgroundColor: #2e2d2b;
      --buttonActiveTextColor: #c7b9b9;
      --borderColumnColor: rgba(255,245,245,0.04);
      color: #aaa;
      opacity: 1;

      .gather-toc-nav {
         font-size: 17px;
         padding: 50px 200px;
      }

      .gather-toc-app-container {
         transform: scale(1);
      }
   }

   &.hidden {
      .overlay;
      opacity: 0;
      pointer-events: none;
      // transform: translate3d(-100%,0,0);

      .gather-toc-app-container {
         transform: scale(1.1);
      }
   }
}

.gather-toc-toggle-control {
   display: flex;
   align-items: center;
   position: absolute;
   bottom: 0px;
   left: 0px;
   height: var(--toolbarHeight);
   padding: 0px 15px;
}
