:global {
   // All constants are set here
   @import './constants/colors.less';
   @import './constants/layout.less';
   @import './constants/typography.less';
   @import './constants/zIndexes.less';

   // Gather app-specific styles
   @import './gather/coverPage.less';
   @import './gather/editor.less';
   @import './gather/note.less';
   @import './gather/notebook.less';
   @import './gather/table.less';
   @import './gather/toc.less';
   @import './gather/viewport.less';

   // Generic UI components
   @import './ui/buttons.less';
   @import './ui/form.less';
   @import './ui/modal.less';
   @import './ui/popover.less';
   @import './ui/typography.less';

   // Global
   html, body {
      -webkit-font-smoothing: subpixel-antialiased;
      text-rendering: optimizeLegibility;
      background-color: var(--bgColor);
      color: var(--textColor);
      transition: 500ms background-color;
      font-size: var(--bodyTextFontSize);
      line-height: var(--bodyTextLineHeight);
   }
}
