// Constants Only

// Dark Mode
.theme-dark {
   --buttonActiveBackgroundColor: #1B1B1C;
   --buttonActiveBackgroundColor: rgb(9, 8, 9);
   --buttonActiveBackgroundColor: transparent;
   --buttonActiveTextColor: #fff;
   --buttonTextColor: #94959c;
   --buttonTextColor: #424347;
   // --bgColor: #131314;
   // --bgColor: rgba(5,0,2,0.85);
   // --bgColor: rgb(9, 8, 9);
   --bgColor: #060606;
   --formFieldBackgroundColor: rgba(255,255,255,0.1);
   --metaColor: rgba(203, 199, 190, 0.5);
   --textColor: #cbc7be;
   --textDisabledColor: rgba(190, 203, 201, 0.3);
   --paragraphTextColor: #c6c4cc;
   --paragraphTextColor: #99969f;
   --titleColor: #fff;
   --buttonBorderColor: #333;
   --borderColor: rgba(255,245,245,0.06);
   // --borderColumnColor: rgba(255,245,245,0.04);
   --borderColumnColor: rgba(255,245,245,0.03);
   // --borderColumnColor: transparent;
   --blockBackgroundColor: rgba(255,255,255,0.02);
   // --rootMenuButtonTextColor: #706c6c;
   // --rootMenuBackgroundColor: rgba(5,0,0,0.2);
   // --rootMenuBackgroundColor: rgb(9, 8, 9);
   --rootMenuButtonTextColor: #060606;
}

// Light Mode
.theme-light {
   // --buttonActiveBackgroundColor: #ebe8e2;
   // --buttonActiveBackgroundColor: rgb(240, 236, 235);
   // --buttonActiveBackgroundColor: rgb(242, 237, 235);
   --buttonActiveBackgroundColor: rgba(231, 228, 226, 0.7);
   --buttonActiveTextColor: #493731;
   --buttonTextColor: #8c8480;
   // --bgColor: #faf7f5;
   --bgColor: #fafafa;
   // --bgColor: rgba(242, 240, 238, 0.5);
   --formFieldBackgroundColor: rgba(0,0,0,0.05);
   --metaColor: rgba(73, 55, 49, 0.5);
   --textColor: #493731;
   --textDisabledColor: rgba(73, 55, 49, 0.3);
   --paragraphTextColor: #837772;
   // --paragraphTextColor: #57544e;
   --titleColor: #504b48;
   --buttonBorderColor: #ccc;
   --borderColor: #d1cec2;
   --borderColumnColor: rgba(241, 238, 233, 0.8);
   // --borderColumnColor: transparent;
   --blockBackgroundColor: #f3f4f4;
   --rootMenuButtonTextColor: #a39692;
   // --rootMenuBackgroundColor: rgba(100,50,0,0.015);
   // --rootMenuBackgroundColor: rgba(100,50,0,0.005);
   --rootMenuBackgroundColor: rgba(242, 240, 238, 0.2);
   --rootMenuBackgroundColor: #f8f8f8;
   --rootMenuBackgroundColor: #fafafa;

   // Contrast Mode
   // --rootMenuBackgroundColor: #000;
   // --borderColumnColor: rgba(241, 238, 233, 0.05);
   // --buttonActiveBackgroundColor: rgb(9, 8, 9);
   // --buttonActiveTextColor: #fff;
   // --buttonTextColor: #424347;

   // --rootMenuBackgroundColor: #fafafa;
   // --rootMenuBackgroundColor: rgba(242, 241, 234, 1);

   // High Contrast
   // --bgColor: #FFFCF5;
   // --rootMenuBackgroundColor: #FFFCF5;

   --buttonActiveBackgroundColor: rgba(231, 224, 222, 0.5);
   // --buttonActiveBackgroundColor: transparent;
   --buttonActiveTextColor: #000;
   --buttonTextColor: #777;
   --borderColor: #e7e7e7;
   --borderColumnColor: #eee;
   --metaColor: #888;
   --paragraphTextColor: #555;
   --textColor: #333;
   --titleColor: #333;
   // --rootMenuBackgroundColor: #f5f5f5;

   // Olive
   --buttonActiveBackgroundColor: #53684E;
   --buttonActiveTextColor: #fff;
   --buttonTextColor: #53684E;
   --metaColor: #53684E;
   --paragraphTextColor: #53684E;
   --textColor: #53684E;
   --titleColor: #53684E;

   // Sepia
   --buttonActiveBackgroundColor: #68624e;
   --buttonActiveTextColor: #fff;
   --buttonTextColor: #68624e;
   --metaColor: #68624e;
   --paragraphTextColor: #68624e;
   --textColor: #68624e;
   --titleColor: #68624e;

   // Rust
   --buttonActiveBackgroundColor: #584443;
   --buttonActiveTextColor: #fff;
   --buttonTextColor: #4e3c3b;
   --metaColor: #4e3c3b;
   --paragraphTextColor: #4e3c3b;
   --textColor: #4e3c3b;
   --titleColor: #493a39;

   // --buttonActiveBackgroundColor: #e8e4e4;
   // --buttonActiveTextColor: #000;
   // --buttonTextColor: #000;
   // --metaColor: #000;
   // --paragraphTextColor: #333;
   // --textColor: #000;
   // --titleColor: #000;

   // --buttonActiveBackgroundColor: #555;
   // --buttonActiveTextColor: #fff;
   // --buttonTextColor: #555;
   // --metaColor: #555;
   // --paragraphTextColor: #555;
   // --textColor: #555;
   // --titleColor: #555;


   // --buttonActiveBackgroundColor: #A75959;
   // --buttonActiveTextColor: #fff;
   // --buttonTextColor: #A75959;
   // // --borderColor: #eee;
   // --metaColor: #A75959;
   // --paragraphTextColor: #A75959;
   // --textColor: #A75959;
   // --titleColor: #A75959;

   // COLOR TEST
   // --bgColor: #040d08;
   // --buttonActiveBackgroundColor: #5fd99b;
   // --buttonActiveTextColor: #000;
   // --buttonTextColor: #f5e662;
   // --borderColor: #5fd99b;
   // --borderColumnColor: #222;
   // --metaColor: #f5e662;
   // --paragraphTextColor: #f5e662;
   // --textColor: #f5e662;
   // --titleColor: #f5e662;
   // --rootMenuBackgroundColor: #166237;
}
