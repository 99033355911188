.gather-notebook-page {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   opacity: 1;
   overflow: auto;
   background-color: var(--bgColor);
   will-change: transform;
   transition: transform 600ms;
   transform: translate3d(0, 0, 0);

   &.facing-left {
      width: 50%;
      border-right: 1px solid var(--borderColumnColor);
      .typography-small;
   }

   &.facing-right {
      width: 50%;
      left: 50%;
      .typography-small;
   }

   &.offscreen {
      transform: translate3d(-125%, 0, 0);

      &.facing-left, &.facing-right {
         transform: translate3d(-225%, 0, 0);
      }
   }
}
