.gather-viewport {
   font-size: var(--bodyTextFontSize);
   line-height: var(--bodyTextLineHeight);
   width: 100vw;
   height: 100vh;
   overflow: hidden;
}

.gather-viewport-titlebar {
   position: fixed;
   top: 0px;
   left: 0px;
   right: 0px;
   width: 100%;
   height: var(--viewportDesktopTitlebarHeight);
   -webkit-user-select: none;
   -webkit-app-region: drag;
   z-index: var(--zIndexViewportTitlebar);
   pointer-events: none;
}
