.gather-note {
   position: relative;
   display: flex;
   width: calc(100% - 0px);
   height: calc(100% - 0px);
   margin: 0px;
   background-color: var(--bgColor);
   border-radius: 4px;
}

.gather-note-content {
   display: flex;
   justify-content: center;
   width: 100%;
   height: 100%;
   overflow: auto;
}

.gather-details-pane {
   width: 240px;
   flex: 0 0 auto;
}

.gather-note-heading-seperator {
   border-top: 1px solid var(--borderColor);
   width: 100%;
   height: 30px;
   margin-top: 90px;
}

.facing-page {
   --mediumMarginTop: 60px;

   .gather-note {
      padding: 0px;
   }

   .gather-note-heading-seperator {
      height: 40px;
      margin-top: 120px;
   }
}
