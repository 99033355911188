:root {
   --appHeadingHeight: 0px;
   --buttonBorderRadius: 4px;
   --buttonHeight: 28px;
   --mediumMarginTop: 90px;
   --toolbarHeight: 50px;
   --viewportDesktopTitlebarHeight: 30px;
   --viewportMarginTop: 10px;
}

.ui-navigation-view {
   &.root {
      --mediumMarginTop: 16vh;
   }

   &.leaf {
      --mediumMarginTop: 16vh;
   }
}

// Electron Test
.desktop-mode {
   --appHeadingHeight: 0px;
   --viewportMarginTop: calc(10px + var(--viewportDesktopTitlebarHeight));

   .ui-navigation-view {
      &.root {
         --mediumMarginTop: 17vh;
      }
   
      &.leaf {
         --mediumMarginTop: 17vh;
      }
   }
}
