.gather-spreadsheet-container {
	position: relative;

	.table-wrapper {
		overflow: hidden; /* Clip any scrollbars that appear */
	}

	.table-footer-cell {
		border: none;
		background-color: transparent !important;
	}
    
	.resize-handle {
		display: block;
		position: absolute;
		cursor: col-resize;
		width: 7px;
		right: 0;
		top: 0;
		z-index: 1;
		border-right: 2px solid transparent;
	}

	.resize-handle:hover {
		border-color: #ccc;
	}
    
	.resize-handle.active {
		border-color: #517ea5;
	}

   table {
		width: 100%;
		display: grid;
		overflow: auto;

		a {
			color: var(--textColor);
			text-decoration: underline;
			font-weight: 600;
		}

		thead, tbody, tr {
			display: contents;
		}

		th span, td span {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			display: block;
		}

		tr td {
			border-bottom: 1px solid var(--borderColor);
		}

		th {
			position: relative;
			font-weight: 600;
			background-color: var(--formFieldBackgroundColor);
			text-align: left;
			padding: 8px 20px;
			min-width: 100px;
		}

		td {
			text-align: left;
			padding: 12px 20px;
			min-width: 100px;
		}

		.data-row {
			&:hover {
				td {
					background-color: var(--formFieldBackgroundColor);
				}
			}
		}
	}
}
