.ui-modal {
   position: absolute;
   top: 0px;
   left: 0px;
   right: 0px;
   bottom: 0px;
   font-size: var(--bodyTextFontSize);
   line-height: var(--bodyTextLineHeight);
   --mediumMarginTop: 14vh;
}
