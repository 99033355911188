.ui-paragraph-text {
   font-size: var(--paragraphTextFontSize);
   line-height: var(--paragraphTextLineHeight);
   color: var(--paragraphTextColor);
   font-weight: 360;
   letter-spacing: 0.2px;
   // font-family: 'erode'; font-weight: 420; font-size: 19px; line-height: 28px;
   // font-family: 'merriweather';
   // font-family: 'roboto-mono'; font-weight: 400; font-size: 15px; line-height: 26px;
}

.ui-title {
   position: relative;
   color: var(--titleColor);
   line-height: 120%;
   margin-top: 1px;
}

.heading-style-sans {
   .ui-title {
      &.lg { font-size: 34px; font-weight: 570; line-height: 38px; letter-spacing: 0px; }
      &.md { font-size: 20px; font-weight: 570; }
      &.sm { font-size: 16px; font-weight: 570; }
   }
}

.heading-style-serif {
   .ui-title {
      font-family: 'garamond';

      &.lg { font-size: 42px; line-height: 50px; font-weight: 500; letter-spacing: -1px; }
      &.md { font-size: 24px; font-weight: 500; letter-spacing: -0.5px; }
      &.sm { font-size: 19px; font-weight: 570; letter-spacing: -0.3px; }
   }
}

.heading-style-serif-titlecase {
   .ui-title {
      font-family: 'garamond';

      &.lg {
         font-size: 24px; line-height: 30px; text-transform: uppercase;
         font-weight: 550; letter-spacing: 2px;
      }
      &.md { font-size: 18px; font-weight: 550; text-transform: uppercase; letter-spacing: -0.5px; }
      &.sm { font-size: 14px; font-weight: 550; text-transform: uppercase; letter-spacing: 0.5px; }

   }
}

.heading-style-sans-titlecase {
   .ui-title {
      // font-family: 'work-sans';

      &.lg {
         font-size: 25px; line-height: 30px;text-transform: uppercase;
         font-weight: 600; letter-spacing: 3px;
      }

      &.md { font-size: 15px; text-transform: uppercase; font-weight: 600; letter-spacing: 0.5px; }
      &.sm { font-size: 13px; text-transform: uppercase; font-weight: 600; letter-spacing: 0.5px; }
   }
}
