.gather-editor {
   font-size: inherit;
   line-height: inherit;
   color: inherit;

   b, strong {
      font-weight: 600;
   }

   h2, h2 strong {
      font-weight: 550;
      font-size: 1.3em;
      margin-bottom: 15px;
      color: var(--titleColor);
   }

   h3, h3 strong {
      font-weight: 600;
      font-size: 1.05em;
      margin-bottom: 5px;
      color: var(--titleColor);
   }
   
   p {
      margin: 0;
   }

   a {
      color: var(--textColor);
      text-decoration: underline;
      opacity: 0.5;
   }

   hr {
      border: 0;
      border-bottom: 1px solid rgb(238, 238, 238);
   }

   hr.active {
      border-bottom: 1px solid #c9b36b;
   }

   blockquote {
      border-left: 10px solid #eee;
      margin: 20px 0;
      padding: 0px 20px;
      color: #777;
   }

   pre {
      border-radius: 3px;
      caret-color: red;
      overflow-x: auto;
      overflow-y: hidden;
      word-wrap: normal;
      font-size: 0.8em;
      font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
      padding: 15px 20px;
      background: rgb(41, 45, 62);
      color: rgb(191, 199, 213);
      line-height: 1.4;
   }
   
   ol {
      margin: 0 0 0 30px;
      padding: 0;
   }
   
   ul {
      margin: 0 0 0 20px;
      padding: 0;
      list-style: none;
   }

   ul li:before {
      content: "• ";
      position: absolute;
      top: -1px;
      left: -15px;
      font-size: 22px;
      color: inherit;
   }

   li {
      position: relative;
      padding: 0 0 0px 10px;
      margin: 0;
      font-size: 0.95em;
   }

   .mc-checklist {
      margin: 10px 0px;
      padding: 0px;
   }

   .mc-checklist li:before {
      content: ""
   }

   .mc-checklist > li {
      position: relative;
      padding: 4px 0px 4px 30px;
      font-size: 0.95em;
      border-bottom: 1px solid var(--borderColor);
   }

   .mc-checklist > li:last-child {
      border-bottom: none;
   }

   .mc-checklist > li input[type="checkbox"] {
      position: absolute;
      top: 0px;
      left: -2px;
      -webkit-appearance: checkbox;
      width: 28px;
      height: 28px;
      opacity: 0;
   }

   .mc-checkbox {
      position: absolute;
      top: 7px;
      left: 0px;

      svg {
         width: 16px !important;
         height: 16px !important;
      }
   }

   .mc-checkbox-icon {
      fill: var(--buttonTextColor);
      stroke: var(--buttonTextColor);
      opacity: 0.8;
   }

   .mc-livetoken {
      display: inline-block;
      margin: 0 1px;
      padding: 3px 3px 2px;
      border-radius: 4px;
      background-color: #eee;
      vertical-align: baseline;
   }

   .mc-livetoken.active {
      box-Shadow: 0 0 0 2px #c9b36b;
   }

   .mc-mediablock {
      margin: 20px 0px;
   }

   .mc-mediablockitem {
      margin-Right: 5px;
      margin-Bottom: 5px;
      display: inline-block;
      height: 150px;
   }

   .mc-mediablockitem img {
      max-width: 100%;
      height: 100%;
   }

   .mc-mediablockitem img.active {
      box-shadow: 0 0 0 4px #c9b36b;
   }
}

.mc-hovertoolbar {
   position: absolute;
   top: -10000px;
   left: -10000px;
   margin-top: -6px;
   opacity: 0;
   background-color: #fff;
   border-radius: 6px;
   transition: opacity 400ms;
   box-shadow: 0px 0px 30px rgba(0,0,0,0.2);
   border: 1px solid #ddd;
   padding: 3px 5px;
   font-family: Switzer;
   display: flex;
   align-items: center;
   z-index: var(--zIndexEditor);
}

.mc-hovertoolbar.debug-mode {
   top: 0;
   left: 0;
   opacity: 1;
}

.mc-toolbar-button {
   border: 1px solid transparent;
   background-color: transparent;
   border-radius: 6px;
   padding: 7px;
   width: 32px;
   font-size: 14px;
   margin: 0;
}

.mc-toolbar-button:hover {
   background-color: #eee;
}

.mc-toolbar-button.active {
   background-color: #eee;
}
