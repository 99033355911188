.ui-popover {
   background-color: rgba(5,0,0,0.9);
   width: 100%;
   max-height: 400px;
   border-radius: 6px;
   padding: 4px 0px;
   box-shadow: 6px 6px 30px rgba(0,0,0,0.05);
   font-size: 13px;
   color: #888;
   overflow: auto;
   --buttonActiveBackgroundColor: rgba(255,255,255,0.1);
   --buttonActiveTextColor: #fff;
   --buttonBorderRadius: 0px;
   --buttonTextColor: #fff;
}
